<template>
  <b-modal
      id="login-or-register-modal"
      ref="login-or-register-modal"
      centered
      size="sm"
      header-class="modal-header-class"
      footer-class="modal-footer-class"
      content-class="modal-content-class"
    >
      
        
          <b-card-text class="font-weight-bolder login-modal-title">
            {{ $t('You do not have an account?') }}
          </b-card-text>
          <b-card-text class="font-weight-normal login-modal-text">
            {{ $t('CreateMenteeAccountMessage') }}
          </b-card-text>
          <b-button variant="primary" :to="{name: 'mentee-register'}">
            {{ $t('Register') }}
          </b-button>

          <hr class="my-2"/>

          <b-card-text class="font-weight-bolder login-modal-title">
            {{ $t('Already have an account?') }}
          </b-card-text>
          <b-card-text class="font-weight-normal login-modal-text">
            {{ $t('MenteeLoginMessage') }}
          </b-card-text>
          <validation-observer ref="signInDetailsObserver" v-slot="{ invalid }">
            <b-form-group>
              <label for="i-sign-in-email">
                {{ $t('SignInEmail') }} <required-field />
              </label>
              <validation-provider name="signInEmail" rules="required" mode="lazy" v-slot="{ errors }">
                <b-form-input id="i-sign-in-email" type="email" v-model="signInEmail" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="i-password">
                  {{ $t('Password') }} <required-field />
                </label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                    <small style="font-size: 13px;">{{ $t('Forgot Password?') }}</small>
                </b-link>
              </div>
              <validation-provider name="password" rules="required" mode="lazy" v-slot="{ errors }">
                <b-input-group>
                  <b-form-input id="i-password" :type="passwordFieldType" v-model="password" />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="outline-primary"
              :disabled="invalid"
              @click="closeModalAndTryLogin"
            >
              {{ $t('SignIn') }}
            </b-button>
          </validation-observer>
        
      
      <template #modal-footer>
        <div>
        </div>
      </template>
  </b-modal>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BCardHeader,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow,
  BLink,
} from 'bootstrap-vue'
import { required } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import LoginMixin from '@mentoring-platform/mixins/LoginMixin'

export default {
  mixins: [
    togglePasswordVisibility,
    LoginMixin
  ],
  components: {
    BButton,
    BCardHeader,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BRow,
    BLink,
    RequiredField,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    closeModalAndTryLogin() {
      this.$refs['login-or-register-modal'].hide();
      this.submitLoginData()
    },
    show() {
      this.$refs['login-or-register-modal'].show()
    }
  }
}
</script>

<style>
  .modal-content-class{
    padding: 0 !important;
  }

  .modal-header-class{
    background: white !important;
    border: none;
  }

  .modal-footer-class{
    border: none;
  }

  .login-modal-title {
    font-size: 18px;
  }

  .login-modal-text {
    font-size: 14px;
  }
</style>
