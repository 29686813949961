<template>
  <div>
    <page-header
      class="mb-3"
      :title="$t('MentoringPackages')"
      :message="$t('Select a package that is suitable for you')"
    />
    <b-row>
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row class="pricing-card match-height">
          <b-col
            v-for="(subscription, index) in availableSubscriptions"
            :key="index"
            md="4"
          >
            <b-card
              align="center"
              no-body
            >
              <b-card-body>
                <div class="subcription-image mb-2 d-flex flex-column flex-end justify-content-end">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/packages/' + subscription.photo)"
                      alt="enterprise svg img"
                    />
                  </div>
                </div>

                <h3>
                  {{ $t(subscription.title) }}
                </h3>
                <b-card-text>
                  {{ $t(subscription.description) }}
                </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <span class="pricing-basic-value font-weight-bolder text-primary">
                      {{ subscription.price | price }}
                    </span>
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(bullet, index2) in subscription.bullets"
                    :key="index2"
                  >
                    {{ $t(bullet.description) }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

              </b-card-body>
              <b-card-footer footer-class="footer-class">
                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="purchase(subscription.id)"
                >
                  {{ $t('Purchase') }}
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center">
      <b-col
        md="8"
        class="pt-4"
      >
        <mentoring-packages-additional-info />
      </b-col>
    </b-row>

    <section class="pt-4">
      <need-help />
    </section>

    <login-or-register-modal
      v-if="!isUserLoggedIn"
      ref="login-or-register-modal"
    />
    <choose-account-modal v-if="!isUserLoggedIn" />
    <create-mentee-profile-as-mentor-modal
      v-if="isUserLoggedIn"
      ref="create-mentee-profile-as-mentor-modal"
      from-mentoring-packages
    />
    <switch-to-mentee-profile-modal
      v-if="isUserLoggedIn"
      ref="switch-to-mentee-profile-modal"
      from-mentoring-packages
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BImg,
  VBModal
} from 'bootstrap-vue'
import LoginOrRegisterModal from '@mentoring-platform/views/components/blocks/LoginOrRegisterModal.vue'
import ChooseAccountModal from '@mentoring-platform/views/components/blocks/ChooseAccountModal.vue'
import CreateMenteeProfileAsMentorModal from '@mentoring-platform/views/components/blocks/CreateMenteeProfileAsMentorModal.vue'
import SwitchToMenteeProfileModal from '@mentoring-platform/views/components/blocks/SwitchToMenteeProfileModal.vue'
import MentoringPackagesAdditionalInfo from '@mentoring-platform/views/components/blocks/MentoringPackages/MentoringPackagesAdditionalInfo.vue'
import { price } from '@mentoring-platform/utils/filters'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { useSubscriptionRepository } from '@mentoring-platform/composables'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'
import NeedHelp from '@mentoring-platform/views/components/blocks/NeedHelp.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BButton,
    BImg,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    NeedHelp,
    ChooseAccountModal,
    LoginOrRegisterModal,
    CreateMenteeProfileAsMentorModal,
    SwitchToMenteeProfileModal,
    MentoringPackagesAdditionalInfo,
    PageHeader
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  filters: {
    price
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('user', {
      isUserLoggedIn: 'isUserLoggedIn',
      hasMenteeAccount: 'getHasMenteeAccount'
    })
  },
  setup() {
    const { availableSubscriptions, getAvailableSubscriptions } = useSubscriptionRepository()
    getAvailableSubscriptions()

    return {
      availableSubscriptions
    }
  },
  methods: {
    async purchase(subscriptionId) {
      if (this.isUserLoggedIn) {
        if (this.$can('read', 'mentee')) {
          this.$router.push({ name: 'checkout', params: { id: subscriptionId } })
        } else if (this.hasMenteeAccount) {
          this.$refs['switch-to-mentee-profile-modal'].show()
        } else {
          this.$refs['create-mentee-profile-as-mentor-modal'].show()
        }
      } else {
        this.$refs['login-or-register-modal'].show()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

.header-class {
  height: 70px;
}

.footer-class {
  border: 0 !important;
}

.subcription-image {
  height: 120px;
}
</style>
